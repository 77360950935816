import { logoutUser } from '@/actions/auth';
import AvatarComponent from '@/components/common/avatar';
import { capitalizeFirstLetter } from '@/helpers/dataHelper';
import { Avatar, Dropdown, Menu } from 'antd';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const UserBar = ({ ...props }) => {
  const dispatch = useDispatch();
  const handleLogout = async (e) => {
    e.preventDefault();
    if (typeof window !== undefined) {
      dispatch(logoutUser());
    }
  };
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    const unparsedUser =
      typeof window !== undefined && window.localStorage.getItem('user');
    setUser(
      unparsedUser && unparsedUser !== 'undefined' ? JSON.parse(unparsedUser) : {}
    );
  }, []);

  return (
    <>
      <span className="mt-1">
        <Dropdown
          overlay={<DropDownMenu user={user} handleLogout={handleLogout} />}
          trigger={['click']}
          overlayStyle={{
            minWidth: '232px !important',
            left: '285.188px',
          }}
          {...props}
          getPopupContainer={(trigger: HTMLElement) =>
            trigger.parentNode as HTMLElement
          }
        >
          {user?.avatar ? (
            <Avatar
              //  size={45}
              src={user?.avatar}
              className="cursor-pointer"
              style={{ height: '40px', width: '40px' }}
            />
          ) : (
            <Avatar
              size="large"
              className="cursor-pointer"
              style={{ backgroundColor: '#283360' }}
            >
              {user?.first_name?.charAt(0).toUpperCase()}
              {user?.last_name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Dropdown>
      </span>
    </>
  );
};
export const UserInfo = ({ user }) => (
  <>
    <Link href="/profile/dashboard" prefetch={false}>
      <a
        className={`user-profile-detail-dropdown p-3 ${
          user?.user_type === 'instructor' && user?.status === 'PENDING'
            ? ''
            : 'border-bottom'
          // 'dropdown-tutor-verify'
        }`}
      >
        <AvatarComponent
          avatar={user?.avatar}
          firstName={user?.first_name}
          lastName={user?.last_name}
          // size={55}
        />
        <div className="user-dropdown-detail">
          <span className="dropdown-profile-name ml-1">
            {`${capitalizeFirstLetter(user?.first_name)} ${capitalizeFirstLetter(
              user?.last_name
            )}`}
            <span className="dropdown-profile-email d-block">{user?.email}</span>
          </span>
        </div>
      </a>
    </Link>
    {user?.user_type === 'instructor' && user?.status === 'PENDING' && (
      <>
        <Link href="/tutor/verify">
          <a className="ant-btn mt-2 d-flex justify-content-center p-1 text-danger border-danger">
            <a>Verify your tutor account!</a>
          </a>
        </Link>
        <hr />
      </>
    )}
    {user?.user_type === 'staff' ||
    user?.user_type === 'partner' ||
    (user?.user_type == 'instructor' && user?.status == 'APPROVE') ? (
      <Link
        href={
          user?.user_type === 'partner'
            ? '/dashboard/partner-clients'
            : '/dashboard/home'
        }
        prefetch={false}
      >
        <a className="nav-link dropdown-profile-menu pb-0">
          <span className="mt-1">Dashboard</span>
        </a>
      </Link>
    ) : (
      ''
    )}
  </>
);

export const DropDownMenu = ({ user, handleLogout }) => (
  <Menu>
    <div className="dropdown-menu-profile">
      <UserInfo user={user} />
      {user?.user_type !== 'partner' && (
        <>
          <Link href="/room" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Rooms</a>
          </Link>
          <Link href="/class-list" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Live class</a>
          </Link>
          <Link href="/mock-test" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Mock Test</a>
          </Link>
          <Link href="/notes" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Notes</a>
          </Link>
          <Link href="/profile/my-attempted-tests" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Attempted Tests</a>
          </Link>
          <Link href="/purchase-history" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Purchase History</a>
          </Link>
          <Link href="/refer" prefetch={false}>
            <a className="nav-link dropdown-profile-menu pb-0">Refer and Earn</a>
          </Link>
        </>
      )}

      <a
        onClick={(e) => handleLogout(e)}
        className="nav-link dropdown-profile-menu pb-0"
      >
        Log out
      </a>
    </div>
  </Menu>
);
export default UserBar;
