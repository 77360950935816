import {
  GET_HOME,
  GET_HOME_SUCCEEDED,
  GET_HOME_FAILED,
  VALUE_CHANGED,
  DELETE_VALUE,
  GET_INFO,
  GET_INFO_SUCCEEDED,
  GET_INFO_FAILED,
  ADD_CONTACT,
  ADD_CONTACT_SUCCEEDED,
  ADD_CONTACT_FAILED,
  GET_COMPANY_INFO,
  GET_COMPANY_INFO_SUCCEEDED,
  GET_COMPANY_INFO_FAILED,
  GET_LANDING_FAQ,
  GET_LANDING_FAQ_SUCCEEDED,
  GET_LANDING_FAQ_FAILED,
  GET_HOME_SEARCH,
  GET_HOME_SEARCH_SUCCESS,
  GET_HOME_SEARCH_FAILURE,
  CLEAR_HOME_DROPDOWN,
  SET_HOME_DATA,
} from './index';

export const getHome = () => {
  return {
    type: GET_HOME,
    payload: {
      url: 'home/',
      onSuccess: GET_HOME_SUCCEEDED,
      onFailure: GET_HOME_FAILED,
    },
  };
};

export const getHomeSearch = ({ name }) => {
  return {
    type: GET_HOME_SEARCH,
    payload: {
      url: `global-search/?name=${name}`,
      onSuccess: GET_HOME_SEARCH_SUCCESS,
      onFailure: GET_HOME_SEARCH_FAILURE,
    },
  };
};
export const clearHomeDropdown = () => {
  return {
    type: CLEAR_HOME_DROPDOWN,
  };
};

export const valueChanged = (field, value) => ({
  type: VALUE_CHANGED,
  payload: { field, value },
});

export const deleteValue = (field) => ({
  type: DELETE_VALUE,
  payload: { field },
});

export const getInfo = () => {
  return {
    type: GET_INFO,
    payload: {
      url: 'info/',
      onSuccess: GET_INFO_SUCCEEDED,
      onFailure: GET_INFO_FAILED,
    },
  };
};

export const getCompanyInfo = (slug) => {
  return {
    type: GET_COMPANY_INFO,
    payload: {
      url: `company/${slug}`,
      onSuccess: GET_COMPANY_INFO_SUCCEEDED,
      onFailure: GET_COMPANY_INFO_FAILED,
    },
  };
};

export const addContact = (data = {}) => {
  return {
    type: ADD_CONTACT,
    payload: {
      data,
      url: `contact-add/`,
      onSuccess: ADD_CONTACT_SUCCEEDED,
      onFailure: ADD_CONTACT_FAILED,
    },
  };
};

export const getPublishedFAQ = () => {
  return {
    type: GET_LANDING_FAQ,
    payload: {
      url: `faq/`,
      onSuccess: GET_LANDING_FAQ_SUCCEEDED,
      onFailure: GET_LANDING_FAQ_FAILED,
    },
  };
};

export const setHomeData = (data = {}) => {
  return {
    type: SET_HOME_DATA,
    payload: { data },
  };
};
