import { Avatar } from "antd"

const onCourseClick = (slug) => {
    var url = window.location.href;
    var domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
    const courseURL = `${window.location.protocol}//${domain}/course/${slug}`
    window.location.href = courseURL;
}
export const courseConverter = (data) => {
    const result = data?.map((each) => ({
        // value: each?.name,
        label: courseSuggestion(each)
    }));
    return result;
}

export const courseConverterNew = (data) => {
    const result = data?.map((each) => (
        courseSuggestion(each, true)
    ));
    return result;
}

const courseSuggestion = (each, isMobile = false) => (
    <div
        className={`d-flex ${isMobile ? 'bg-white border-bottom p-2 cursor-pointer course-dropdown-suggestion' : ''}`}
        onClick={() => onCourseClick(each?.slug)}
        key={each?.id}
    >
        <Avatar
            className='mt-3 mr-3'
            size={50}
            src={each?.image || "https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_12_06_07_37_16_PCL_nursing_copy.jpg"} shape="square" />

        <div>
            <span className="text-bold-700 text-dark" style={{ color: '#19b192', fontWeight: 700 }}>
                {`${each.name || ''}`}{' '}
            </span>
            <br />
            <small className="text-muted">
                Course by: {each?.tutor?.name}{' '}
            </small>
        </div>
    </div>
)
