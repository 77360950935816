import Image from 'next/image';
import { Url } from 'url';

export const removeKeyFromObj = (obj, key) => {
  const data = { ...obj };
  const value = Object.keys(data).filter((item) => key.indexOf(item) === -1);
  value.forEach((e) => delete data[e]);
  return data;
};

export const removeEmpty = (obj) => {
  const data = { ...obj };
  Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
  return data;
};

export const removeImageKeyFromObj = (obj, key) => {
  const data = { ...obj };
  if (data[key]?.startsWith('http')) {
    delete data[key];
  }
  return data;
};

export const capitalizeFirstLetter = (name) => {
  return name?.charAt(0).toUpperCase() + name?.slice(1);
};

export const capitalizeFirstLetterOnly = (name) => {
  return name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();
};

export const slugTextConverter = (slug) => {
  const word = slug
    ?.split('-')
    ?.map((el) => el?.charAt(0)?.toUpperCase() + el?.slice(1)?.toLowerCase());
  const joinedText = word?.join(' ');
  return joinedText;
};

export function linkify(text) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text?.replace(urlRegex, function (url) {
    return (
      '<a target="_blank" style="color:#1a0dab" href="' +
      url +
      '"><u>' +
      url +
      '</></a>'
    );
  });
}
export function isLinkPresent(text) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  // return { urlRegex.test(text), urlRegex.exec(text) };
  return urlRegex.exec(text);
}

export function isObjEmpty(object) {
  for (const property in object) {
    return false;
  }
  return true;
}

export const imagesLink = [
  'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_09_25_20_46_51_BALLB_copy_2.jpg',
  // 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_09_25_20_48_08_nursing_copy_1.jpg',
  // 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_09_27_19_28_25_PTE_course.png',
  // 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_06_13_10_24_21_BE_B_Arch_cover_1.png',
  // 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_08_22_16_07_14_See_online_coaching.png',
  // 'https://d1k0ex3y89plmw.cloudfront.net/media/public/content_material/2022_05_08_13_00_21_cover_2_web_1_1.png',
];

export const socialMediaImages = [
  {
    id: 1,
    title: 'Facebook',
    url: 'https://www.facebook.com/learnsic.official',
    image: '/images/svgs/social_media/fb.svg',
  },
  {
    id: 4,
    title: 'Instagram',
    url: 'https://www.instagram.com/learnsic.official',
    image: '/images/svgs/social_media/insta.svg',
  },
  {
    id: 2,
    title: 'TikTok',
    url: 'https://www.tiktok.com/@learnsicofficial',
    image: '/images/svgs/social_media/tiktok.svg',
  },
  {
    id: 3,
    title: 'YouTube',
    url: 'https://www.youtube.com/channel/UCWPiuPuIeVGnKQrpc435SDA',
    image: '/images/svgs/social_media/yt.svg',
  },
];
export const SocialMediaIcons = ({ src }) => (
  <Image src={src} width={'25.749px'} height="29.425px" />
);

export const generateStrokeColor = (percentage) => {
  return percentage <= 25
    ? {
        '0%': '#89c540',
        '100%': '#89c540',
      }
    : percentage > 25 && percentage <= 50
    ? {
        '0%': '#ffc107',
        '100%': '#ffc107',
      }
    : percentage > 50 && percentage <= 75
    ? {
        '0%': '#283360',
        '100%': '#283360',
      }
    : percentage > 75 &&
      percentage <= 100 && {
        '0%': '#28a745',
        '100%': '#28a745',
      };
};

export const parseYouTubeVideoLink = (youtube_link: string): string => {
  const videoId: Array<string> =
    youtube_link !== undefined && youtube_link.match(/(?<=v=)[^&]+/);
  if (videoId !== null && videoId.length > 0)
    return `https://www.youtube.com/embed/${videoId[0]}`;
  return '';
};

export const calculateTotalCountLikes = (array: Array<any>) => {
  let totalValue = 0;

  array?.map((el) => {
    totalValue = totalValue + el?.reaction_count;
  });

  return totalValue;
};

export const calculateTopThreeEmojisUsed = (array: Array<any>) => {
  const sortedArray = array?.sort((a, b) => b?.reaction_count - a?.reaction_count);

  const arr = sortedArray.slice(0, 3);

  return arr;
};

// Sorting the students a/c to class status
export const sortStudentACStatus = (array: Array<any>) => {
  const sortedArrayData =
    array?.length > 0 &&
    array?.sort((a, b) => {
      if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
        return 1; // Move a to end
      } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
        return -1; // Move b to end
      } else {
        return 0; // Keep the same order
      }
    });

  return sortedArrayData;
};

// Function to check if an object with a specific id exists in the array
export const containsObject = (array, newObj, compareKey) => {
  return array.findIndex((obj) => obj[compareKey] === newObj[compareKey]) !== -1;
};

// Function to update or insert an object into the array based on its id
export const updateOrInsertObject = (array, newObj, compareKey) => {
  let newArr = array;
  const index = array.findIndex((obj) => obj[compareKey] === newObj[compareKey]);
  if (index !== -1) {
    // Update existing object
    newArr[index] = newObj;
  } else {
    // Insert new object
    newArr.push(newObj);
  }
  return newArr;
};

export function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
