import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const NavbarLogo = () => {
  //partner data
  const partnerData = useSelector((state: any) => state?.auth?.partner?.data);

  const [partner, setPartner] = useState<any>(partnerData);

  const router = useRouter();

  //for getting partner data from localstorage
  useEffect(() => {
    const auth = window.localStorage.getItem('auth');
    const parsedAuth = auth && auth !== 'undefined' ? JSON.parse(auth) : null;
    const isLoggedIn = parsedAuth?.isLoggedIn;
    if (!isLoggedIn) {
      const localPartner = localStorage.getItem('myPartner');
      if (partnerData && Object.keys(partnerData).length !== 0) {
        setPartner(partnerData);
      } else if (localPartner && localPartner !== 'undefined') {
        setPartner(JSON.parse(localPartner));
      } else {
        setPartner({});
      }
    } else {
      const userPartner = localStorage.getItem('user');
      const parsedUser =
        userPartner && userPartner !== 'undefined' ? JSON.parse(userPartner) : {};
      if (parsedUser.partner && userPartner !== 'undefined') {
        setPartner(parsedUser?.partner);
      } else {
        setPartner({});
      }
    }
  }, [router?.query?.partner, partnerData]);

  useEffect(() => {
    if (partnerData && partnerData?.register_name) {
    } else if (!partnerData) {
      router.push(
        {
          pathname: router?.pathname,
          query: '',
        },
        undefined,
        { scroll: false }
      );
    }
  }, [partnerData]);

  return (
    <div>
      {partner && Object.keys(partner).length !== 0 ? (
        <div className="d-flex flex-column align-items-center">
          {partner?.logo ? (
            <>
              <img
                src={partner?.logo}
                alt="logo"
                style={{ maxWidth: '150px', maxHeight: '46px' }}
              />
              <p
                style={{
                  margin: '0px',
                  marginBottom: '-8px',
                  fontSize: '10px',
                  textAlign: 'center',
                }}
              >
                POWERED BY LEARNSIC
              </p>
            </>
          ) : (
            <>
              <p style={{ margin: '0px', fontSize: '10px', textAlign: 'center' }}>
                POWERED BY LEARNSIC
              </p>
              <p
                style={{
                  margin: '0px',
                  fontSize: '20px',
                  textAlign: 'center',
                  fontWeight: '700',
                }}
              >
                {partner.trade_name ? partner.trade_name : partner.register_name}
              </p>
            </>
          )}
        </div>
      ) : (
        <Image src="/logo.svg" alt="logo" width="196" height="46" />
      )}
    </div>
  );
};

export default NavbarLogo;
